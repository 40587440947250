<template>
  <ul
    v-if="designs.length>0"
    class="design-list"
  >
    <transition-group
      appear
      name="design-list"
      tag="span"
    >
      <design-list-item
        v-for="design in designs"
        :key="design.uuid"
        :design="design"
        :previews="previews"
        :row-actions="getRowActions(design)"
        @missing-thumbnail="missingThumbnail"
      />
    </transition-group>
    <li
      v-if=" currentPage !== lastPage"
      class="w-100 d-flex justify-content-center align-items-center mb-5 mt-3"
    >
      <secondary-button @click="fetchData(currentPage+1)">
        <span>Load more</span>
      </secondary-button>
    </li>
  </ul>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex"
import Utils from "../../utils"
import Language from "../../i18n/en"
import DesignModal from "../common/modal/DesignModal"
import DeleteModal from "../common/modal/DeleteModal"
import ConfirmModal from "../common/modal/ConfirmModal"
import SecondaryButton from "../common/controls/SecondaryButton"
import DesignListItem from "./DesignListItem"
import {
  ACTIVE_DESIGN_STATUSES, AD_FORMATS,
  DESIGN_CREATION_TYPE_ADTRON,
  DESIGN_CREATION_TYPE_EXTERNAL,
  DESIGN_PROGRESS_DESIGN,
  DESIGN_PROGRESS_DESIGN_INIT,
  DESIGN_PROGRESS_READY,
  DESIGN_PROGRESS_READY_PENDING,
  PRODUCT_LIVING_ADS
} from "@/constants"
import { v4 as uuidv4 } from "uuid"
import PackageBuildModal from "../../components/modals/PackageBuildModal"
import PackageDownloadModal from "../../components/modals/PackageDownloadModal"
import { DesignRoutes } from "../../api/routes"
import WagawinUtils from "@/wagawinUtils"

export default {
  name: "DesignsList",
  components: {
    SecondaryButton,
    DesignListItem
  },
  props: {
    campaignId: {
      type: String,
      default: null
    },
    campaignEditToken: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      count: 0,
      previews: [],
      designs: [],
      currentPage: 1,
      lastPage: 10
    }
  },
  computed: {
    ...mapState(["currentUser", "selectedFilters", "availableFeatures"]),
    showArchivedDesigns () {
      return Boolean(this.currentUser.settings.showArchived)
    },
    ACTIVE_DESIGN_STATUSES () {
      return ACTIVE_DESIGN_STATUSES
    }
  },
  created () {
    this.loadDesigns()
    this.subs = []
    this.previousPackStatus = {}
    this.packageTimeout = {}
  },
  beforeUnmount () {
    this.unsubscribeSubs()
  },
  methods: {
    ...mapMutations(["changeLoadingState"]),
    ...mapActions(["fetchCampaign", "setupPreviewUpdatedWsConnection", "destroyPreviewUpdatedWsConnection", "destroyBuildStatusWsConnection", "destroyPackageUpdateWsConnection", "destroyPackageFailedWsConnection"]),
    getDesignShowroomHref (design) {
      if (this.availableFeatures.includes("gr:wagawinFeatures")) {
        const adtronUrl = `${window.location.origin}/showroom/${design.unique_id}?token=${design.preview_token}`
        return WagawinUtils.replaceAdtronUrlToWagawin(adtronUrl)
      }
      return window.location.origin + "/showroom/" + design.unique_id + "?token=" + design.preview_token
    },
    getPublishHref (design) {
      const url = new URL("/adtags/" + this.campaignId, window.location.origin)
      url.searchParams.set("token", this.campaignEditToken)
      url.searchParams.set("design", design.unique_id)

      return url.toString()
    },
    unsubscribeSub (designUuid) {
      this.destroyBuildStatusWsConnection({ designUuid })
      this.destroyPackageUpdateWsConnection({ designUuid })
      this.destroyPackageFailedWsConnection({ designUuid })
      this.destroyPreviewUpdatedWsConnection({ designUuid })
    },
    unsubscribeSubs () {
      this.subs.forEach(designUuid => this.unsubscribeSub(designUuid))
    },
    setDesignPreview (designUuid, screenshot) {
      const preview = this.previews.find(p => p.designUuid === designUuid)
      if (preview) {
        preview.screenshot = screenshot
      } else {
        this.previews.push({
          designUuid,
          screenshot
        })
      }
    },
    getRowActions (design) {
      const actions = []

      if (!design.archived && design.permissions.view) {
        actions.push({
          href: design.progress === DESIGN_PROGRESS_DESIGN_INIT ? "#" : this.getDesignShowroomHref(design),
          class: "item",
          content: design.progress === DESIGN_PROGRESS_DESIGN_INIT ? "Preview is not ready yet" : "Preview",
          click: () => {
            window.open(design.progress === DESIGN_PROGRESS_DESIGN_INIT ? "#" : this.getDesignShowroomHref(design))
          },
          icon: "preview",
          disabled: design.progress === DESIGN_PROGRESS_DESIGN_INIT
        })
      }

      if (!design.archived && design.creation_type === DESIGN_CREATION_TYPE_ADTRON && design.permissions.edit) {
        actions.push({
          href: "/designs/" + design.unique_id + "/duplicate",
          class: "item",
          content: "Duplicate",
          click: () => this.duplicateDesign(design.uuid),
          icon: "duplicate"
        })
      }

      if (!design.archived && design.permissions.edit) {
        actions.push({
          href: "#",
          class: "item",
          content: "Settings",
          click: () =>{
            if (design.ad_product === PRODUCT_LIVING_ADS) {
              this.$router.replace(`/designs/${design.unique_id}/edit?livingAds=${design.format}`)
            } else if (design.format === AD_FORMATS.vast_interstitial) {
              this.$router.replace(`/designs/${design.unique_id}/edit?vast=${design.format}`)
            } else {
              this.openDesignWizard(design)
            }
          },
          icon: "settings"
        })
      }

      if (!design.archived && design.creation_type === DESIGN_CREATION_TYPE_EXTERNAL && design.permissions.edit) {
        actions.push({
          href: "#",
          class: "item",
          content: "Download from repository",
          click: () => this.fetchFromRepository(design),
          icon: "download-package"
        })
      }

      if (!design.archived && design.creation_type === DESIGN_CREATION_TYPE_EXTERNAL && design.permissions.edit) {
        actions.push({
          href: "#",
          class: "item",
          content: "Host creative",
          click: () => this.hostAd(design),
          icon: "publish"
        })
      }

      if (!design.archived && design.creation_type === DESIGN_CREATION_TYPE_EXTERNAL && this.currentUser?.roles.super_admin) {
        actions.push({
          href: "#",
          class: "item",
          content: "Create package",
          click: () => this.showPackageModal(design),
          icon: "package"
        })
      }

      if (!design.archived && design.creation_type === DESIGN_CREATION_TYPE_EXTERNAL && this.currentUser?.roles.super_admin && design.download_links && design.download_links.length) {
        actions.push({
          href: "#",
          class: "item",
          content: "Download package",
          click: () => this.showPackageDownloadModal(design),
          icon: "download-package"
        })
      }

      if (this.campaignEditToken && !design.archived && design.creation_type === DESIGN_CREATION_TYPE_ADTRON && this.ACTIVE_DESIGN_STATUSES.includes(design.progress)) {
        actions.push({
          href: this.getPublishHref(design),
          class: "item",
          content: "Go to adtag",
          icon: "publish",
          click: () => {
            window.open(this.getPublishHref(design))
          }
        })
      }

      if ((this.currentUser.roles.super_admin || this.currentUser.roles.admin) && !design.archived && design.permissions.edit) {
        actions.push({
          href: "/designs/" + design.unique_id + "/archive",
          class: "item",
          content: "Archive",
          click: () => this.archiveAd(design.unique_id),
          icon: "archive"
        })
      }

      if ((this.currentUser.roles.super_admin || this.currentUser.roles.admin) && design.archived && design.permissions.edit) {
        actions.push({
          href: "/designs/" + design.unique_id + "/unarchive",
          class: "item",
          content: "Unarchive",
          click: () => this.unarchiveAd(design.unique_id),
          icon: "archive"
        })
      }

      if (this.currentUser.roles.super_admin && design.permissions.delete) {
        actions.push({
          href: "#",
          class: "item",
          content: "Delete",
          click: () => this.destroyDesign(design.uuid),
          icon: "delete"
        })
      }

      return actions
    },
    loadDesigns () {
      this.fetchData(1, 5, "updated_at", "DESC", false)
    },
    fetchFromRepository (design) {
      this.$store.dispatch("fetchFromRepository", design).then(() => {
        this.$toasted.success(Language.designs.success.fetch)
      }).catch((e) => {
        console.error(e)
        this.$toasted.error(Language.designs.error.fetch)
      })
    },
    openDesignWizard (design) {
      this.fetchCampaign(this.campaignId).then(campaign => {
        this.$modal.show(DesignModal, {
          campaign,
          design,
          updateCallback: this.loadDesigns
        })
      })
    },
    fetchData (page = 1, limit = 5, sortBy = "updated_at", sortWay = "DESC", concat = true) {
      const loadDesigns = this.$store.dispatch("fetchDesigns", {
        campaignId: this.campaignId,
        limit,
        page,
        sortBy,
        sortWay,
        filters: this.getFilters(),
        archived: this.showArchivedDesigns
      })

      loadDesigns.then((resp) => {
        this.count = resp.data.meta.total
        this.currentPage = resp.data.meta.current_page
        this.lastPage = resp.data.meta.last_page

        if (concat) {
          this.designs = Utils.removeDuplicatesByKey([...this.designs, ...resp.data.data], "uuid")
        } else {
          this.designs = resp.data.data
        }

        this.unsubscribeSubs()
        this.designs.forEach((design) => {
          if (window.isRunningIntercomTour) {
            this.setDesignPreview(design.uuid, design.screenshot + "?" + Date.now())
            return
          }

          this.setDesignPreview(design.uuid, "/upload/screenshots/preview_" + design.uuid + ".jpg?" + Date.now())
          this.setupPreviewUpdatedWsConnection({
            designUuid: design.uuid,
            callback: () => {
              this.previewUpdatedWsConnectionCallback(design.uuid)
            }
          })
          this.$store.dispatch("setupDesignLockWsConnection", {
            designUuid: design.uuid,
            callback: (data) => {
              this.designLockWsConnectionCallback(design.uuid, data)
            }
          })

          if (design.creation_type === DESIGN_CREATION_TYPE_EXTERNAL && this.currentUser?.roles.super_admin) {
            this.$store.dispatch("setupBuildStatusWsConnection", {
              designUuid: design.uuid,
              callback: this.wsStatusCallback
            })
            this.$store.dispatch("setupPackageUpdateWsConnection", {
              designUuid: design.uuid,
              callback: this.wsPackagesCallback
            })
            this.$store.dispatch("setupPackageFailedWsConnection", {
              designUuid: design.uuid,
              callback: this.displayPackageFailedToast
            })

            this.subs.push(design.uuid)
          }
        })
      })
    },
    wsStatusCallback (data) {
      if (data.packageStatus === 1 && this.previousPackStatus[data.uuid] !== data.packageStatus) {
        if (this.packageTimeout[data.uuid]) {
          clearTimeout(this.packageTimeout[data.uuid])
        }
        this.$toasted.success(Language.designs.success.pack_finished)
        this.packageTimeout[data.uuid] = setTimeout(() => {
          this.$apiClient.get(DesignRoutes.downloadPackage(data.unique_id), {
            responseType: "arraybuffer"
          }).then((response) => {
            Utils.downloadFileFromResponse(response)
          })
        }, 5000)
      }
      this.previousPackStatus[data.uuid] = data.packageStatus
    },
    wsPackagesCallback () {
      this.fetchData(this.currentPage, 5, "updated_at", "DESC", false)
    },
    displayPackageFailedToast () {
      this.$toasted.error(Language.designs.error.pack_failed)
    },
    previewUpdatedWsConnectionCallback (designUuid) {
      this.setDesignPreview(designUuid, "/upload/screenshots/preview_" + designUuid + ".jpg?" + Date.now())
    },
    showPackageModal (design) {
      this.$modal.show(PackageBuildModal, { designUuid: design.uuid })
    },
    showPackageDownloadModal (design) {
      this.$modal.show(PackageDownloadModal, { designUuid: design.uuid, links: design.download_links })
    },
    designLockWsConnectionCallback (designUuid, data) {
      const design = this.designs.find(d => d.uuid === designUuid)
      if (design) {
        design.locked = true
        design.locked_by = data.locked_by
        design.editors = [...design.editors.filter(u => u.uuid !== data.locked_by.uuid), data.locked_by]
      }
    },
    missingThumbnail (designUuid) {
      this.setDesignPreview(designUuid, null)
    },
    destroyDesign (designUuid) {
      const uri = DesignRoutes.deleteDesign(designUuid)

      this.$modal.show(DeleteModal, {
        endpoint: uri,
        title: "Delete Design",
        successMessage: Language.designs.success.delete,
        errorMessage: Language.designs.error.delete,
        afterDelete: () => {
          const design = this.designs.find(d => d.uuid === designUuid)
          this.$eventHub.$emit("modify-creative-count", { campaignId: this.campaignId, value: -1 })
          if (design) {
            this.designs = this.designs.filter(d => d.uuid !== design.uuid)
          }
        }
      })
    },
    duplicateDesign (designUuid) {
      this.changeLoadingState(true)
      this.$store.dispatch("duplicateDesign", designUuid).then(
        response => {
          const design = response.data.data
          this.designs.unshift(design)
          this.$eventHub.$emit("modify-creative-count", { campaignId: this.campaignId, value: 1 })
          this.changeLoadingState(false)
          this.$toasted.success(Language.designs.success.duplicate)
        },
        err => {
          this.changeLoadingState(false)
          if ([419].includes(err.response.status)) return
          if (err.response.status === 400) {
            this.$toasted.error(err.response.data.message)
          } else {
            this.$toasted.error(Language.designs.error.duplicate)
          }
        }).finally(() => {
        this.changeLoadingState(false)
      })
    },
    hostAd (design) {
      const adtag = this.buildTagConfig(design)
      return this.$store.dispatch("host", { uuid: design.uuid, tag: adtag }).then(
        response => {
          this.$toasted.success(Language.designs.success.host_started)
          design.hosted = response.data.data.hosted
          design.hosted_url = response.data.data.hosted_url
        },
        err => {
          if ([419].includes(err.response.status)) return
          if (err.response.status === 400) {
            this.$toasted.error(err.response.data.message)
          } else {
            this.$toasted.error(Language.designs.error.host_started)
          }
        }
      )
    },
    buildTagConfig (design) {
      const clickouts = {}
      if (design) {
        const modules = Utils.getAllModulesFromVariants(design.variants)
        modules.forEach((m) => {
          m.data.forEach((d) => {
            if (d.type === "clickout") {
              if (!clickouts.hasOwnProperty(m.htmlId)) {
                clickouts[m.htmlId] = d.value
              }
            }
          })
        })
      }
      return {
        tagId: this.tag ? this.tag.uuid : uuidv4(),
        identifier: "Default",
        clickouts,
        clickTags: {},
        events: {},
        trackingPixels: {},
        designUuid: design.uuid
      }
    },
    archiveAd (designUniqueId) {
      this.$modal.show(ConfirmModal, {
        title: "Archive design",
        actionButton: "Archive",
        description: "Are you sure to archive this design?",
        isWarning: true,
        successMessage: Language.designs.success.archived,
        errorMessage: Language.designs.error.archived,
        confirm: () => {
          this.$store.dispatch("archiveDesign", designUniqueId).then(() => {
            const design = this.designs.find(d => d.unique_id === designUniqueId)
            if (design) {
              design.archived = true
            }
          })
        }
      })
    },
    unarchiveAd (designUniqueId) {
      this.$modal.show(ConfirmModal, {
        title: "Unarchive design",
        description: "Are you sure to unarchive this design?",
        actionButton: "Unarchive",
        isWarning: true,
        successMessage: Language.designs.success.unarchived,
        errorMessage: Language.designs.error.unarchived,
        confirm: () => {
          this.$store.dispatch("unarchiveDesign", designUniqueId).then(() => {
            const design = this.designs.find(d => d.unique_id === designUniqueId)
            if (design) {
              design.archived = false
            }
          })
        }
      })
    },
    getFilters () {
      if (!this.selectedFilters) {
        return {}
      }

      return {
        progress: this.selectedFilters.status ? this.getProperStatusFilters(this.selectedFilters.status) : []
      }
    },
    getProperStatusFilters (statuses) {
      const properStatuses = statuses.map(row => row.value)

      // when 'design' status is selected add also 'design_init' to filters
      if (properStatuses.includes(DESIGN_PROGRESS_DESIGN)) {
        properStatuses.push(DESIGN_PROGRESS_DESIGN_INIT)
      }

      // when 'ready' status is selected add also 'ready_pending' to filters
      if (properStatuses.includes(DESIGN_PROGRESS_READY)) {
        properStatuses.push(DESIGN_PROGRESS_READY_PENDING)
      }

      return properStatuses
    }
  }
}
</script>
