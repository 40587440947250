<template>
  <base-modal
    id="importCampaignDesignsFromAnotherCompanyModal"
    ref="modal"
    class="modal-auto-height text-left"
    :show-close-button="true"
    size="extra-small"
    title="Import campaign designs from another company"
  >
    <template #content>
      <div>
        <div class="form-group">
          <text-input
            v-model:value="campaignId"
            label="Campaign id"
            @keydown.enter.prevent="$event.target && $event.target.blur"
          />
        </div>
      </div>
    </template>
    <template #actions>
      <base-button
        :min-width="114"
        size="m"
        @click.prevent="modal.close"
      >
        Cancel
      </base-button>
      <primary-button
        :disabled="campaignId.length < 7"
        :min-width="114"
        size="m"
        @click.prevent="queueImportCampaignDesignsFromAnotherCompany"
      >
        Import
      </primary-button>
    </template>
  </base-modal>
</template>
<script>
import Language from "../../i18n/en"

export default {
  props: {
    campaignUniqueId: {
      type: String,
      default: null
    },
    callback: {
      type: Function,
      default: null
    }
  },
  data () {
    return {
      modal: {
        close: () => {}
      },
      campaignId: ""
    }
  },
  mounted () {
    this.modal = this.$refs.modal
  },
  methods: {
    queueImportCampaignDesignsFromAnotherCompany () {
      this.$toasted.success(Language.designs.success.import_campaign_designs_from_another_company_started)
      const promise = this.$store.dispatch("importCampaignDesignsFromAnotherCompany", {
        campaignUniqueId: this.campaignUniqueId,
        clonedCampaignUniqueId: this.campaignId
      }).then(
        response => {
          this.$toasted.success(response.data.message)

          if (this.callback) {
            this.callback(response)
          }
        },
        err => {
          if ([419].includes(err.response.status)) return
          if (err.response.status === 400) {
            this.$toasted.error(err.response.data.message)
          } else {
            this.$toasted.error(Language.designs.error.import_failed)
          }
        }
      )
      this.modal.close()

      return promise
    }
  }
}
</script>
