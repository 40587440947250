<template>
  <base-modal
    id="importDesignModal"
    ref="modal"
    class="modal-auto-height text-left"
    :show-close-button="true"
    size="extra-small"
    title="Import design from external instance"
  >
    <template #content>
      <div>
        <div class="form-group">
          <input-dropdown
            v-model:value="activeApiUrl"
            label="External site URL"
            :options="availableInstances"
          />
        </div>
        <div class="form-group">
          <text-input
            id="designId"
            v-model:value="designId"
            label="Unique id of cloned design"
            name="designId"
            type="text"
            @keydown.enter.prevent="$event.target && $event.target.blur"
          />
        </div>
        <div class="form-group">
          <text-input
            id="apiLogin"
            v-model:value="email"
            label="E-mail to external site (will not be stored)"
            name="apiLogin"
            type="text"
            @keydown.enter.prevent="$event.target && $event.target.blur"
          />
        </div>
        <div class="form-group">
          <text-input
            id="apiPassword"
            v-model:value="password"
            label="Password for external site (will not be stored)"
            name="apiPassword"
            type="password"
            @keydown.enter.prevent="$event.target && $event.target.blur"
          />
        </div>
      </div>
    </template>
    <template #actions>
      <base-button
        :min-width="114"
        size="m"
        @click.prevent="modal.close"
      >
        Cancel
      </base-button>
      <primary-button
        :disabled="!activeApiUrl.value.length || !email.length || !password.length || !designId.length"
        :min-width="114"
        size="m"
        @click.prevent="queueImportDesign"
      >
        Import
      </primary-button>
    </template>
  </base-modal>
</template>
<script>
import Language from "../../i18n/en"
import InputDropdown from "@/components/common/dropdown/InputDropdown.vue"

export default {
  components: { InputDropdown },
  props: {
    campaignUniqueId: {
      type: String,
      default: null
    },
    callback: {
      type: Function,
      default: null
    }
  },
  data () {
    return {
      modal: {
        close: () => {}
      },
      activeApiUrl: { title: "https://lab.adtron.io", value: "https://lab.adtron.io" },
      email: "",
      password: "",
      designId: "",
      availableInstances: [
        { title: "https://dev.adtron.network", value: "https://dev.adtron.network" },
        { title: "https://test.adtron.network", value: "https://test.adtron.network" },
        { title: "https://lab.adtron.io", value: "https://lab.adtron.io" }
      ]
    }
  },
  mounted () {
    this.modal = this.$refs.modal
  },
  methods: {
    queueImportDesign () {
      this.$toasted.success(Language.designs.success.import_started)
      const promise = this.$store.dispatch("importDesign", {
        campaignUniqueId: this.campaignUniqueId,
        designUniqueId: this.designId,
        apiUrl: this.activeApiUrl.value,
        email: this.email,
        password: this.password
      }).then(
        response => {
          this.$toasted.success(response.data.message)

          if (this.callback) {
            this.callback(response)
          }
        },
        err => {
          if ([419].includes(err.response.status)) return
          if (err.response.status === 400) {
            this.$toasted.error(err.response.data.message)
          } else {
            this.$toasted.error(Language.designs.error.import_failed)
          }
        }
      )
      this.modal.close()

      return promise
    }
  }
}
</script>
