import Utils from "../utils.js"
import { GestureModuleType } from "@/components/designer/module_types/types"
import { EventManager } from "./eventManager"
import { AnimationManager } from "./animationManager"

export class PreviewManager {
  /**
   * Returns module icon for given module type
   * @param {object} block
   * @param {module} module
   * @param {float} currentTime
   * @param {design} design
   * @param {module[]} modules
   */
  static calculateChanges (block, module, currentTime, design, modules) {
    return AnimationManager.getCalculatedChanges(currentTime, block, module, design, modules)
  }

  /**
   * Returns module icon for given module type
   * @param {array} pendingAnimations
   * @param {array} animations
   * @param {array} allAnimations
   * @param {module} module
   * @param {float} currentTime
   * @param {module[]} modules
   * @param {design} design
   */
  static getModuleCurrentPreviewProperties (pendingAnimations, animations, allAnimations, module, currentTime, modules, design) {
    let currentOpacity = 0
    let currentBorderRadius = 0
    let currentTransform = {}
    let currentWidth = null
    let currentHeight = null
    if (pendingAnimations.length === 0) {
      const sortedStockAnimations = animations.filter(a => a.type !== AnimationManager.PROPS_MIXED)
      const sortedCustomAnimations = animations.filter(a => a.type === AnimationManager.PROPS_MIXED)

      sortedStockAnimations.concat(sortedCustomAnimations).forEach((animationBlock) => {
        const changes = this.calculateChanges(animationBlock, module, currentTime, design, modules)

        if (changes.hasOwnProperty("left")) {
          currentTransform = Object.assign({}, currentTransform, { translateX: changes.left + "px" })
        }
        if (changes.hasOwnProperty("top")) {
          currentTransform = Object.assign({}, currentTransform, { translateY: changes.top + "px" })
        }
        if (changes.hasOwnProperty("rotate3d")) {
          currentTransform = Object.assign({}, currentTransform, { rotate3d: changes.rotate3d })
        }
        if (changes.hasOwnProperty("width")) {
          currentWidth = changes.width
        }
        if (changes.hasOwnProperty("height")) {
          currentHeight = changes.height
        }
      })

      currentOpacity = Object.assign(
        {},
        ...sortedStockAnimations.map(b => this.calculateChanges(b, module)),
        ...sortedCustomAnimations.map(b => this.calculateChanges(b, module))
      ).opacity

      currentBorderRadius = Object.assign(
        {},
        ...sortedStockAnimations.map(b => this.calculateChanges(b, module)),
        ...sortedCustomAnimations.map(b => this.calculateChanges(b, module))
      ).borderRadius
    } else {
      currentOpacity = Object.assign({}, ...allAnimations
        .filter((block) => {
          return block.start <= currentTime
        })
        .sort((a, b) => a.start - b.start)
        .map((block) => {
          return this.calculateChanges(block, module)
        })).opacity

      // No keyframes upfront – read the first animation opacity
      if (currentOpacity === undefined) {
        currentOpacity = Object.assign({}, allAnimations
          .sort((a, b) => a.start > b.start)
          .map((block) => {
            return this.calculateChanges(block, module)
          })
          .filter((o) => o.hasOwnProperty("opacity"))[0]
        ).opacity
      }

      currentBorderRadius = Object.assign({}, ...allAnimations
        .filter((block) => {
          return block.start <= currentTime
        })
        .sort((a, b) => a.start - b.start)
        .map((block) => {
          return this.calculateChanges(block, module)
        })).borderRadius

      // No keyframes upfront – read the first animation opacity
      if (currentBorderRadius === undefined) {
        currentBorderRadius = Object.assign({}, allAnimations
          .sort((a, b) => a.start > b.start)
          .map((block) => {
            return this.calculateChanges(block, module)
          })
          .filter((o) => o.hasOwnProperty("opacity"))[0]
        ).borderRadius
      }
    }

    const result = {
      opacity: currentOpacity,
      "border-radius": currentBorderRadius,
      transform: Utils.encodeTransform(currentTransform),
      "transform-origin": module.preview.transformOrigin
    }

    if (currentWidth !== null) {
      result.width = currentWidth
    }

    if (currentHeight !== null) {
      result.height = currentHeight
    }

    return result
  }

  /**
   * Returns module icon for given module type
   * @param {event} event
   */
  static convertEventToTimelineProperty (event) {
    const timelineData = []
    if (event.action.hasOwnProperty(EventManager.ACTION_PLAY_ANIMATION) || event.action.hasOwnProperty(EventManager.ACTION_PLAY_CUSTOM_ANIMATION)) {
      const property = event.action.hasOwnProperty(EventManager.ACTION_PLAY_ANIMATION) ? EventManager.ACTION_PLAY_ANIMATION : EventManager.ACTION_PLAY_CUSTOM_ANIMATION
      const duration = event.action[property].duration
      const loopDelay = event.action[property].loopDelay
      const easing = event.action[property].easing || null
      const looped = Boolean(event.action[property].looped)
      const uuid = event.uuid

      const timelineProperty = {
        duration,
        easing,
        looped,
        uuid,
        loopDelay,
        start: event.trigger.startsWith("with:") ? event.trigger.split(":").pop() : 0
      }

      if (event.action.hasOwnProperty(EventManager.ACTION_PLAY_CUSTOM_ANIMATION)) {
        timelineProperty.type = "mixed"
        timelineProperty.from = event.action[property].from
        timelineProperty.to = event.action[property].to
      } else {
        timelineProperty.type = event.action[property].animationName
      }

      timelineData.push(timelineProperty)
    }

    return timelineData
  }

  /**
   * Returns module icon for given module type
   * @param {event[]} events
   * @param {module[]} modules
   * @param {string} activeTimelineTab
   * @param {design} design
   * @param {float} currentTime
   */
  static checkTimelineBlock (events, modules, activeTimelineTab, design, currentTime) {
    const rootEvent = events.find(e => e.uuid === activeTimelineTab)

    modules.forEach((module) => {
      let timelineData
      if (activeTimelineTab === null || (design && activeTimelineTab === design.uuid)) {
        timelineData = Utils.cloneDeep(module.timeline) // Remove observer
        if (module.type === GestureModuleType && [design?.uuid, null].includes(activeTimelineTab)) {
          const duration = Utils.getModuleDataValue(module, "gestureDuration", 1000)
          const start = Utils.getModuleDataValue(module, "gestureDelay", 0)
          timelineData = timelineData.concat([{
            from: [],
            to: [],
            duration,
            start,
            type: AnimationManager.PROPS_GESTURE_ANIMATION,
            uuid: module.uuid
          }])
        }
      } else {
        timelineData = []

        if (rootEvent) {
          if (rootEvent.actionTarget === module.uuid) {
            timelineData = timelineData.concat(this.convertEventToTimelineProperty(rootEvent))
          }

          const relatedEvents = events.filter((e) => {
            return e.trigger.startsWith(`with:${rootEvent.uuid}`) && e.actionTarget === module.uuid
          })

          relatedEvents.forEach((e) => {
            timelineData = timelineData.concat(this.convertEventToTimelineProperty(e))
          })
        }
      }

      this.animateData(timelineData, module, currentTime, modules, design, events)
    })
  }

  /**
   * Returns module icon for given module type
   * @param {array} timelineData
   * @param {module} module
   * @param {float} currentTime
   * @param {module[]} modules
   * @param {design} design
   * @param events
   */
  static animateData (timelineData, module, currentTime = 0, modules, design, events) {
    let moduleProps = {}

    const pendingAnimations = timelineData.filter((a) => {
      return a.start + a.duration > currentTime
    })
    const sortedAnims = timelineData
      .sort((a, b) => {
        return a.start - b.start
      })

    const currentFinalProperties = this.getModuleCurrentPreviewProperties(pendingAnimations, sortedAnims, timelineData, module, currentTime, modules, design)

    for (const timelineBlockIndex in timelineData) {
      const block = timelineData[timelineBlockIndex]

      const newChanges = this.calculateChanges(block, module, currentTime, design, modules)
      if ((currentTime >= block.start && currentTime <= block.duration + block.start)) {
        moduleProps = Object.assign({}, moduleProps, newChanges)
      } else {
        const sortedAnimations = timelineData.sort((a, b) => {
          return a.start + a.duration > b.start + b.duration
        })
        // If none of animations are pending – save the last frame
        if (pendingAnimations.length === 0) {
          moduleProps = Object.assign({},
            moduleProps,
            this.calculateChanges(sortedAnimations[sortedAnimations.length - 1], module, currentTime, design, modules)
          )
        } else {
          moduleProps = Object.assign({},
            this.calculateChanges(sortedAnimations[sortedAnimations.length - 1], module, currentTime, design, modules),
            moduleProps
          )
        }
      }
    }

    const newProperties = Utils.cloneDeep(this.animateObject(module, Object.assign(moduleProps, currentFinalProperties)))

    // we extract all custom interaction modules and check if we are within. If so - use .from properties for initialPreview
    const interactionEvents = events
      .filter((e) => e.target === module.uuid && e.action.hasOwnProperty(EventManager.ACTION_PLAY_CUSTOM_ANIMATION))
      .sort((a, b) => a.start - b.start)

    let initialPreviewProperties = {}
    if (interactionEvents.length) {
      const timelineProp = this.convertEventToTimelineProperty(interactionEvents[0])
      const changes = this.calculateChanges(timelineProp[0], module, parseInt(timelineProp[0].start), design, modules)
      initialPreviewProperties = this.animateObject(module, changes)
    }
    module.initialPreview = {...module.preview, ...newProperties, ...initialPreviewProperties}
  }

  /**
   * Returns module icon for given module type
   * @param {module} module
   * @param {props} props
   */
  static animateObject (module, props) {
    const newProperties = AnimationManager.getTransformPropsByAnimationBlock(props)

    if (module.preview.hasOwnProperty("transform") && newProperties.hasOwnProperty("transform")) {
      newProperties.transform = Utils.encodeTransform(Object.assign({}, Utils.parseTransform(module.preview.transform), Utils.parseTransform(newProperties.transform)))
    }

    return newProperties
  }

  /**
   * Returns synchronized initial preview
   * @param {variant[]} variants
   * @param {event[]} events
   * @param {design} design
   * @param {float} currentTime
   */
  static synchronizeInitialPreview (variants, events, design, currentTime) {

    variants.forEach(variant => {
      const variantEvents = events.filter(e => e.design_variant_uuid === variant.uuid || e.design_variant_uuid === null)
      const eventsUuid = variantEvents.map(e => e.uuid)
      const modules = Utils.getAllModules(variant.scenes)
      modules.forEach(m => {
        m.preview.active = false
      })
      const playAnimationEventModules = modules.filter(m=> eventsUuid.includes(m.uuid))
      const playAnimationEventTabs = playAnimationEventModules.map(m => m.uuid)
      const timelineTabs = [design.uuid, ...playAnimationEventTabs]
      timelineTabs.forEach(tabUuid => {
        this.checkTimelineBlock(variantEvents, modules, tabUuid, design, currentTime)
      })
    })
    return variants
  }
}
