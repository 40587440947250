<template>
  <base-modal
    ref="modal"
    :image="require('../../../../images/modal/create-brand-infographics.svg')"
    :scrollable="true"
    :show-close-button="false"
    :title="title"
    @submit="createBrand"
  >
    <template #content>
      <div class="container">
        <div class="row d-flex">
          <div class="col">
            <text-input
              v-model:value="brandName"
              :error="form.errors.errors.name && form.errors.errors.name.length > 0"
              label="Brand Name"
              :maxlength="30"
              :min-width="510"
              placeholder="Enter brand name"
              @input="clearErrorsName"
            />
          </div>
        </div>
        <div class="row d-flex pt-4">
          <div class="col">
            <color-boxes
              v-model:value="activeColor"
              :available-colors="colors"
              label="Choose Colour"
            />
          </div>
        </div>
        <div class="row d-flex pt-4">
          <div
            v-if="!cropperInit"
            class="col"
          >
            <upload
              :file="file"
              label="Upload Image"
              type="brand"
              @uploaded="fileUploaded"
            />
          </div>
        </div>
      </div>
      <div
        v-if="file && file.width !== file.height"
        class="cropper"
      >
        <cropper
          v-if="cropperInit"
          :ratios="['1:1']"
          :zoom-ratio="zoom"
        />
      </div>
    </template>
    <template #actions>
      <div class="container  ">
        <div class="row d-flex justify-content-sm-between align-content-center">
          <div class="column d-flex  justify-content-center align-content-center">
            <div
              v-if="cropperInit"
              class="d-flex justify-content-center align-content-center align-items-center zoom-container"
            >
              <icon
                class="zoom-container__image--sm"
                name="image-placeholder"
              />
              <base-slider
                :id="'brandSlider'"
                v-model:value.number="zoom"
                :max="10"
                :min="0"
                :name="'brandSlider'"
                :step="1"
                :value="0"
              />
              <icon
                class="zoom-container__image--m"
                name="image-placeholder"
              />
            </div>
          </div>
          <div class="column">
            <div class="row">
              <template v-if="!cropperInit">
                <base-button
                  size="xl"
                  @click.prevent="modal.close"
                >
                  Cancel
                </base-button>
                <primary-button
                  :disabled="brandName.trim().length === 0"
                  size="xl"
                  @click.prevent="createBrand"
                >
                  {{ brand ? "Save" : "Create" }}
                </primary-button>
              </template>
              <template v-else>
                <base-button
                  size="xl"
                  @click.prevent="$eventHub.$emit('crop-cancel-action', $event);"
                >
                  Cancel
                </base-button>
                <primary-button
                  size="xl"
                  @click.prevent="$eventHub.$emit('crop-image-action', $event);"
                >
                  Crop
                </primary-button>
              </template>
            </div>
          </div>
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { Form } from "@/classes/form"
import Cropper from "../../designer/element_types/Cropper"
import BaseSlider from "../form/BaseSlider"
import ColorBoxes from "../colorbox/ColorBoxes"
import Upload from "../upload/Upload"
import { CompanyRoutes } from "../../../api/routes"

export default {
  components: {
    Cropper,
    BaseSlider,
    ColorBoxes,
    Upload
  },
  props: {
    brand: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      default: "Create Brand"
    },
    action: {
      type: Function,
      default: null
    }
  },
  data () {
    return {
      modal: {
        close: () => {}
      },
      cropperInit: false,
      file: null,
      activeColor: "#b7bac0",
      colors: [
        "#55D8FF",
        "#4892FF",
        "#6B0FE5",
        "#BD74FF",
        "#FF7E83",
        "#F87335",
        "#E59B0F",
        "#ffc351",
        "#34D17E",
        "#2CD344"
      ],
      brandName: "",
      form: new Form({
        name: "",
        file: null,
        color: null
      }),
      zoom: 0
    }
  },
  mounted () {
    this.modal = this.$refs.modal

    if (this.brand) {
      this.brandName = this.brand.name
      this.file = this.brand.file
      this.activeColor = this.brand.color
    }
    this.$eventHub.$on("crop-finished", this.cropFinished)
    this.$eventHub.$on("crop-cancel", this.cropCancelled)
  },
  beforeUnmount () {
    this.$eventHub.$off("crop-finished")
    this.$eventHub.$off("crop-cancel")
  },
  methods: {
    clearErrorsName () {
      this.form.errors.errors.name = []
    },
    cropFinished (file) {
      this.file = file
      this.cropperInit = false
      this.zoom = 0
    },
    cropCancelled () {
      this.file = null
      this.cropperInit = false
      this.zoom = 0
    },
    fileUploaded (file) {
      this.file = file
      if (file.width !== file.height || this.cropImgData) {
        this.$store.commit("setCropData", {
          id: file.id,
          src: file.imagepath
        })
        this.cropperInit = false
        this.$nextTick(() => {
          this.cropperInit = true
        })
      } else {
        this.cropperInit = false
      }
    },
    createBrand () {
      if (this.file !== null) {
        if (this.file.width !== this.file.height) {
          return
        }
        this.form.file = this.file.id
      }

      this.form.name = this.brandName
      this.form.color = this.activeColor

      if (this.brand) {
        return this.form
          .put(CompanyRoutes.updateBrand(this.brand.uuid))
          .then(this.onSuccess)
      }

      return this.form
        .post(CompanyRoutes.createBrand())
        .then(this.onSuccess)
    },
    onSuccess () {
      this.modal.close()
      if (this.action) {
        this.action()
      }
    }
  }
}
</script>
