<template>
  <li
    class="campaign-list__element-wrapper"
  >
    <div
      :class="['campaign-list__element', campaign.archived ? 'archived': '']"
      @click="toggleCampaign(campaign.uuid)"
    >
      <div class="avatar-row">
        <div class="avatars-stacked">
          <div
            v-for="(editor, index) in getFilteredEditors(campaign.campaign_editors)"
            :key="`editor-${editor.uuid}`"
            class="avatar-wrapper"
          >
            <avatar
              :alt="[editor.firstname, editor.lastname].join(' ')"
              :avatar="editor.avatar"
              :size="index === getFilteredEditors(campaign.campaign_editors).length -1 ? 38 : 26"
              :title="[editor.firstname, editor.lastname].join(' ')"
              :tooltip="true"
              :user-id="editor.uuid"
            />
          </div>
        </div>
      </div>
      <div class="campaign">
        <campaign-row
          :campaign="campaign"
          :tags="true"
          :show-campaign-id="isAdmin"
        />
      </div>
      <div class="duration">
        <div class="campaign-date">
          {{ campaign.campaign_start ? Utils.parseDate(campaign.campaign_start, false) : "" }} -
          {{ campaign.campaign_end ? Utils.parseDate(campaign.campaign_end, false) : "" }}
        </div>
        <div
          v-if="campaign.duration"
          class="campaign-duration"
        >
          {{ Utils.getFriendlyDuration(campaign.duration) }}
        </div>
      </div>
      <div class="impressions">
        <div class="impressions-chart">
          <div
            v-tippy="{arrow: true, content:getTooltipContent(campaign), allowHTML:true}"
            class="impressions-chart__chart"
          >
            <circle-chart
              :max="Math.max(0, campaign.targetedNumber)"
              :size="36"
              :progress-color="getCampaignCircleColor(campaign.trackTargetType)"
              :value="getCampaignDeliveredTarget(campaign)"
            />
          </div>
        </div>
        <div class="impressions-text">
          <span class="impression-text__count">
            {{ getDeliveredTargetValue(campaign) }}
          </span>
          <span class="impressions-chart__count">
            {{
              getDeliveredTargetPercent(campaign)
            }}%
          </span>
        </div>
      </div>
      <div class="actions">
        <div class="action-row">
          <primary-button
            v-if="!campaign.archived"
            icon="add"
            :min-width="140"
            @click.prevent.stop="createDesign(campaign.uuid)"
          >
            Add Creative
          </primary-button>
          <pin-button
            v-if="showPinButton"
            :key="'pin-button' + campaign.uuid"
            :active="campaign.pin"
            @pin="pinCampaign(campaign.uuid)"
          />
          <div
            v-if="rowActions.length > 0"
            class="dropdown-holder"
            @click.prevent.stop
          >
            <context-dropdown
              :min-width="182"
              :options="rowActions"
              :placement="'bottom-end'"
            />
          </div>
        </div>
      </div>
    </div>
    <transition name="fadeHeight">
      <design-list
        v-if="!!campaign.active"
        :campaign-edit-token="campaign.edit_token"
        :campaign-id="campaign.uuid"
      />
    </transition>
  </li>
</template>

<script>
import ContextDropdown from "../common/dropdown/ContextDropdown"
import DesignList from "./DesignList"
import Utils from "../../utils"
import PinButton from "../common/PinButton"
import { mapState } from "vuex"

export default {
  name: "CampaignListItem",
  components: {
    ContextDropdown,
    DesignList,
    PinButton
  },
  props: {
    campaign: {
      type: Object,
      required: true
    },
    rowActions: {
      type: Array,
      required: true
    },
    showPinButton: {
      type: Boolean,
      required: true
    },
    canToggleCampaign: {
      type: Boolean,
      required: true
    }
  },
  emits: ["toggle-dropdown", "create-design", "pin-campaign", "toggle-campaign"],
  computed: {
    ...mapState(["currentUser"]),
    Utils () {
      return Utils
    },
    isAdmin () {
      return this.currentUser?.roles.super_admin
    }
  },
  methods: {
    getDeliveredTargetPercent (campaign) {
      if (campaign.trackTargetType === "impressions") {
        return Math.min(Utils.getImpressionsPercent(campaign.deliveredImpressions, campaign.targetedNumber), 100)
      }

      return Math.min(Utils.getImpressionsPercent(campaign.deliveredClicks, campaign.targetedNumber), 100)
    },
    getDeliveredTargetValue (campaign) {
      const deliveredTargetValue = campaign.trackTargetType === "impressions" ? campaign.deliveredImpressions : campaign.deliveredClicks
      return Utils.parseImpressionCount(deliveredTargetValue)
    },
    getCampaignDeliveredTarget (campaign) {
      return campaign.trackTargetType === "impressions" ? campaign.deliveredImpressions : campaign.deliveredClicks
    },
    getCampaignCircleColor (target) {
      return target === "impressions" ? "#a2c100" : "#5d3eff"
    },
    getFilteredEditors (editors) {
      return editors.filter(editor => Boolean(editor) === true)
    },
    toggleCampaign (campaignUuid) {
      if (this.canToggleCampaign) {
        this.$emit("toggle-campaign", campaignUuid)
      }
    },
    createDesign (campaignUuid) {
      this.$emit("create-design", campaignUuid)
    },
    getTooltipContent (campaign) {
      return Utils.getRichTooltip(`Target ${campaign.trackTargetType}`, null, Utils.getCampaignLiveDate(campaign), Utils.parseNumber(campaign.targetedNumber))
    },
    pinCampaign (campaignUuid) {
      this.$emit("pin-campaign", campaignUuid)
    }
  }
}
</script>
