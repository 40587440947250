<template>
  <img
    v-tippy="{arrow: true}"
    :alt="alt"
    class="rounded-full avatar"
    :content="title"
    :src="getAvatarSrc()"
    :style="getImageStyle()"
    :title="title"
    @error="error"
    @show="showTooltip"
  />
</template>
<script>
import Utils from "../../utils"
import { AVATAR_SIZE, ThumbnailResolver } from "@/thumbnailResolver"

export default {
  name: "Avatar",
  props: {
    userId: {
      type: [Number, String],
      default: ""
    },
    alt: {
      type: String,
      default: ""
    },
    avatar: {
      type: String,
      default: ""
    },
    tooltip: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    size: {
      type: Number,
      default: 16
    },
    customSrc: {
      type: String,
      default: null
    },
    useThumb: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    showTooltip () {
      return this.tooltip && this.title.length > 0
    },
    parseThumbUrl (avatar) {
      return ThumbnailResolver.isEnabled() ? ThumbnailResolver.resolve(
        avatar.replace(/\.(.*)$/, ""),
        AVATAR_SIZE
      ) : `/upload/avatars/${avatar}`
    },
    getImageStyle () {
      return {
        width: this.size + "px",
        height: this.size + "px"
      }
    },
    error (e) {
      e.target.src = this.fallbackImage()
    },
    fallbackImage () {
      const nameSplit = this.title && this.title.length ? this.title.split(" ") : "Undefined"
      return Utils.namedAvatar(nameSplit.length ? nameSplit[0] : "", String(nameSplit.length > 1 ? nameSplit[1] : ""), this.userId, this.size, false)
    },
    getAvatarSrc () {
      if (window.isRunningIntercomTour === true) {
        return this.avatar ? this.avatar : this.fallbackImage()
      }
      return this.customSrc
        ? this.customSrc
        : this.avatar ? (this.useThumb ? this.parseThumbUrl(this.avatar) : `${process.env.VUE_APP_API_URL}/upload/avatars/${this.avatar}`) : this.fallbackImage()
    }
  }
}
</script>
