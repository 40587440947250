<template>
  <div
    class="base-modal"
    :class="'base-modal-' + size"
    :style="getModalStyle()"
  >
    <div
      class="card"
      :class="{'modal-fixed-height': scrollable}"
    >
      <slot name="modal-close">
        <div
          v-if="showCloseButton"
          class="modal-close"
          @click.prevent="close"
        >
          <inline-svg :src="require('../../../../images/modal/close-popup.svg')" />
        </div>
      </slot>
      <slot
        v-if="size === 'big'"
        name="modal-side-image"
      >
        <div class="modal-side">
          <div class="modal-side-content">
            <slot name="modal-side-content">
            </slot>
          </div>
          <inline-svg
            v-if="image"
            :src="image"
          />
        </div>
      </slot>
      <slot name="modal-header">
        <div class="modal-header">
          <inline-svg
            v-if="size === 'small' && image"
            :src="image"
          />
          <h2 v-if="title">
            <span>
              {{ title }}
            </span>
          </h2>
        </div>
      </slot>
      <slot name="content-container">
        <div class="modal-content">
          <slot name="content">
          </slot>
        </div>
      </slot>
      <slot name="actions-container">
        <div class="modal-actions">
          <hr v-if="size === 'big' && disableActionLine === false">
          <slot name="actions">
          </slot>
        </div>
      </slot>
    </div>
  </div>
</template>
<script>
import InlineSvg from "vue-inline-svg"
import { mapMutations, mapState } from "vuex"

export default {
  name: "BaseModal",
  components: {
    InlineSvg
  },
  props: {
    title: {
      type: String,
      default: "Modal title"
    },
    scrollable: {
      type: Boolean,
      default: false
    },
    disableActionLine: {
      type: Boolean,
      default: false
    },
    image: {
      type: String,
      default: null
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: "small",
      validator: (val) => ["extra-small", "small", "big", "auto"].indexOf(val) !== -1
    }
  },
  emits: ["submit"],
  computed: {
    ...mapState(["modal"])
  },
  mounted () {
    window.addEventListener("keyup", this.keyup)
  },
  beforeUnmount () {
    window.removeEventListener("keyup", this.keyup)
  },
  methods: {
    ...mapMutations(["closeModal"]),
    keyup (e) {
      if (e.key.toLowerCase() === "enter") {
        this.$emit("submit")
      }
    },
    getModalStyle () {
      let width = 1170 + "px"
      let height = 798 + "px"
      switch (this.size) {
        case "extra-small":
          width = 425 + "px"
          height = 0
          break

        case "auto":
          width = "auto"
          height = 0
          break

        case "small":
          width = 580 + "px"
          height = 644 + "px"
      }

      return {
        width,
        minHeight: height
      }
    },
    close () {
      if (this.modal?.actions?.onClose) {
        this.modal.actions.onClose()
      }
      this.closeModal()
    }
  }
}
</script>
