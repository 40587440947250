<template>
  <div
    class="campaign-row"
    @click="(e) => $emit('click', e)"
  >
    <brand
      :key="`campaign-brand-${campaign.uuid}`"
      :brand="campaign.brand"
      class="campaign-brand"
      :radius="10"
      :size="42"
    />
    <div
      v-if="details || tags || dates"
      class="campaign-details"
    >
      <component
        :is="preventClick ? 'a' : 'router-link'"
        v-tippy
        class="campaign-name"
        :content="campaign.name"
        :to="preventClick ? '' : `/campaign/${ campaign.unique_id}/details`"
        @click="e => preventClick ? e.preventDefault() : e"
        @show="showTooltip(campaign.name)"
      >
        {{ campaign.name }}
      </component>
      <div class="campaign-row-meta">
        <span
          v-if="tags && campaign.tags"
          class="tags tag-list"
        >
          <tooltip-too-long
            v-for="tag in campaign.tags.slice(0,1)"
            :key="tag.uuid"
            class="campaign-tag tag"
            :length="24"
            :text="tag.name "
          />
          <span
            v-if="campaign.tags && campaign.tags.length > 1"
            v-tippy="{allowHTML:true}"
            class="campaign-tag tag"
            :content="campaign.tags.slice(1).map((row) => row.name).join('<br />')"
          >+{{ campaign.tags.length - 1 }}</span>
        </span>

        <div class="d-flex flex-column">
          <span
            v-if="showCampaignId"
            class="campaign-creative-count text-muted"
          >ID: {{ campaign.unique_id }}</span>
          <span
            v-if="details"
            class="campaign-creative-count text-muted"
          >{{ calculatedCreativeCount }}</span>
        </div>

        <span
          v-if="dates"
          class="campaign-date"
        >
          {{ campaign.campaign_start ? Utils.parseDate(campaign.campaign_start, false) : "" }} -
          {{ campaign.campaign_end ? Utils.parseDate(campaign.campaign_end, false) : "" }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import Utils from "../../../utils"
import InlineSvg from "vue-inline-svg"

export default {
  name: "CampaignRow",
  components: { InlineSvg },
  props: {
    campaign: {
      type: Object,
      required: true
    },
    details: {
      type: Boolean,
      default: true
    },
    showCampaignId: {
      type: Boolean,
      default: false
    },
    dates: {
      type: Boolean,
      default: false
    },
    tags: {
      type: Boolean,
      default: false
    },
    preventClick: {
      type: Boolean,
      default: true
    }
  },
  emits: ["click"],
  data () {
    return {
      creativeCount: 0
    }
  },
  computed: {
    calculatedCreativeCount () {
      const count = this.creativeCount
      if (count === 1) {
        return count + " creative"
      }

      return count + " creatives"
    },
    Utils () {
      return Utils
    }
  },
  created () {
    this.creativeCount = this.campaign.creative_count
    this.$eventHub.$on("modify-creative-count", this.modifyCreativeCount)
  },
  beforeUnmount () {
    this.$eventHub.$off("modify-creative-count", this.modifyCreativeCount)
  },
  methods: {
    showTooltip (name) {
      return name.length > 0
    },
    modifyCreativeCount ({campaignId, value}) {
      if (campaignId === this.campaign.uuid) {
        this.creativeCount += value
      }
    }
  }
}
</script>
