<template>
  <div>
    <label
      v-if="label"
      class="input-label"
      :class="optional ? 'input-label-optional' : ''"
      :for="uuid"
    >
      <span>
        {{ label }}
        <icon
          v-if="informationTooltip"
          class="info-tooltip"
          name="info"
          :tooltip="informationTooltip"
          :tooltip-max-width="220"
          tooltip-placement="right-start"
        />
      </span>
    </label>
    <template v-if="single">
      <slot
        name="single-option-preview"
        :option="value"
      />
    </template>
    <multiselect
      :id="uuid"
      :key="uuid+dropdownKey"
      ref="multiselect"
      class="adt-dropdown multiselect multi rich"
      :class="multiselectClasses"
      deselect-label="Can't remove this value"
      :label="trackLabel ? trackLabel : 'title'"
      :model-value="value"
      :allow-empty="true"
      :options="shouldAddSelectAllOption ? sortedOptions : options"
      :placeholder="placeholder"
      :searchable="searchable"
      :active="true"
      :show-labels="false"
      :max-height="maxHeight === null ? NaN : maxHeight"
      :style="{
        minWidth: minWidth + 'px',
        maxWidth: maxWidth ? maxWidth + 'px' : null,
        height: height ? height + 'px' : null
      }"
      :multiple="single === false"
      :close-on-select="false"
      :clear-on-select="clearOnSelect"
      :track-by="trackBy"
      :always-active="isDropdown === false"
      @close="updateKey"
      @update:model-value="emitUpdateValue"
    >
      <template #selection="{ values }">
        <div
          v-if="icon"
          class="icon-search-container"
        >
          <icon :name="icon" />
        </div>
        <div
          v-if="displayTags && maxDisplayed === null && single === false"
          class="wrap-container"
          :style="{'max-width': values.length === 0 ? 0 : null}"
        >
          <div
            v-for="(optionValue, optionIndex) in values"
            :key="[optionValue.title, optionIndex].join('-')"
            v-tippy="{arrow: true}"
            class="option"
            :content="optionValue.title"
          >
            <slot
              name="option-preview"
              :option="optionValue"
            />
            <icon
              name="close"
              @click.prevent="removeOption(optionValue)"
            />
          </div>
        </div>
        <template v-if="displayTags && maxDisplayed !== null && single === false">
          <div
            v-for="(optionValue, optionIndex) in values.slice(0, maxDisplayed)"
            :key="[optionValue.title, optionIndex].join('-')"
            v-tippy="{arrow: true}"
            class="option"
            :content="optionValue.title"
          >
            <slot
              name="option-preview"
              :option="optionValue"
            />
            <icon
              name="close"
              @click.prevent="removeOption(optionValue)"
            />
          </div>
          <div
            v-if="values.length > maxDisplayed"
            v-tippy="{arrow: true, allowHTML: true}"
            class="option and-more"
            :content="`and ${values.length - maxDisplayed} more: <br>${values.slice(maxDisplayed).map(v => v.title ? v.title : '' ).join(',<br>')}`"
          >
            <span>
              +{{ values.length - maxDisplayed }}
            </span>
          </div>
        </template>
        <div class="dropdown-arrow-container">
          <icon
            v-if="showArrow"
            name="dropdown-arrow"
          />
        </div>
      </template>
      <template #placeholder>
        <slot name="placeholder" />
      </template>
      <template #option="props">
        <icon
          v-if="props.option.icon"
          :name="props.option.icon"
        />
        <div class="option__row">
          <slot
            name="option-row"
            :option="props.option"
          />
          <div
            v-if="single === false"
            :key="props.option.title"
            class="option__row__actions"
          >
            <div class="add-btn">
              <slot name="add-button">
                <checkbox
                  v-if="props.option.value !== 'selectAll'"
                  :value="false"
                  @click.prevent
                />
              </slot>
            </div>
            <div
              class="added"
            >
              <slot name="added-button">
                <checkbox
                  :value="true"
                  @click.prevent
                />
              </slot>
            </div>
          </div>
        </div>
      </template>
    </multiselect>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid"
import Utils from "@/utils"

export default {
  props: {
    icon: {
      type: String,
      default: null
    },
    optional: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    value: {
      type: [Array, Object],
      default: () => []
    },
    displayTags: {
      type: Boolean,
      default: true
    },
    minWidth: {
      type: Number,
      default: 260
    },
    maxWidth: {
      type: Number,
      default: null
    },
    options: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: "Select"
    },
    isDropdown: {
      type: Boolean,
      default: false
    },
    showArrow: {
      type: Boolean,
      default: false
    },
    informationTooltip: {
      type: String,
      default: null
    },
    trackBy: {
      type: String,
      default: "title"
    },
    trackLabel: {
      type: String,
      default: "title"
    },
    maxDisplayed: {
      type: Number,
      default: 5
    },
    searchable: {
      type: Boolean,
      default: true
    },
    maxHeight: {
      type: Number,
      default: null
    },
    height: {
      type: Number,
      default: null
    },
    single: {
      type: Boolean,
      default: false
    },
    clearOnSelect: {
      type: Boolean,
      default: true
    },
    shouldAddSelectAllOption: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update:value"],
  data () {
    return {
      dropdownKey: 1,
      multiselectOptions: []
    }
  },
  computed: {
    sortedOptions () {
      const options = Utils.cloneDeep(this.multiselectOptions)
      return options.sort((a, b) => {
        if (a.value === "selectAll" || b.value === "selectAll") {
          return -1
        }
        return 0
      })
    },
    multiselectClasses () {
      return {
        single: this.single,
        "adt-dropdown-optional": this.optional,
        "is-dropdown": this.isDropdown,
        multiline: this.maxDisplayed === null
      }
    }
  },
  created () {
    this.uuid = uuidv4()
    this.multiselectOptions = Utils.cloneDeep(this.options)
  },
  mounted () {
    if (this.shouldAddSelectAllOption) {
      this.multiselectOptions.push({
        title: "Select all",
        name: "Select all",
        value: "selectAll",
        uuid: "selectAll"
      })
    }
  },
  methods: {
    selectAll () {
      const optionsWithoutSelectAll = this.multiselectOptions.filter(option => option.value !== "selectAll")
      if (optionsWithoutSelectAll.length === this.value.length) {
        this.$emit("update:value", [])
      } else {
        this.$emit("update:value", Utils.cloneDeep(optionsWithoutSelectAll || []))
      }
    },
    emitUpdateValue (value) {
      if (value.some(v => v.value === "selectAll")) {
        this.selectAll()
        return
      }
      this.$emit("update:value", value)
    },
    removeOption (option) {
      this.$refs.multiselect?.removeElement(option)
    },
    updateKey () {
      this.dropdownKey++
    }
  }
}
</script>
