<template>
  <div class="modals-container">
    <div
      v-if="isOpen"
      class="vm--container"
      :class="{'alwaysOnTop': alwaysOnTop}"
    >
      <div
        class="vm--overlay"
        @click="closeModal"
      >
      </div>
      <div
        class="vm--modal adt-modal"
      >
        <component
          :is="view"
          v-bind="options"
          v-model="model"
        />
      </div>
    </div>
  </div>
</template>
<script>
import InlineSvg from "vue-inline-svg"
import { mapState } from "vuex"

export default {
  name: "BaseModal",
  components: {
    InlineSvg
  },
  emits: ["submit"],
  data () {
    return {
      model: {}
    }
  },
  computed: {
    ...mapState(["modal"]),
    alwaysOnTop () {
      return this.options?.alwaysOnTop
    },
    isOpen () {
      return this.modal.isOpen
    },
    view () {
      return this.modal.view
    },
    options () {
      return this.modal.options
    },
    actions () {
      return this.modal.actions
    }
  },
  methods: {
    closeModal () {
      if (this?.actions?.onClose) {
        this.actions.onClose()
      }

      this.$store.commit("closeModal")
    }
  }

}
</script>

<style>
.vm--block-scroll {
  overflow: hidden;
  width: 100vw;
}
.vm--container {
  position: fixed;
  box-sizing: border-box;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;

  &.alwaysOnTop {
    z-index: 99999999 !important;
  }
}
.vm--overlay {
  position: fixed;
  box-sizing: border-box;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  /* z-index: 999; */
  opacity: 1;
}
.vm--container.scrollable {
  height: 100%;
  min-height: 100vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.vm--modal {
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  background-color: white;
  border-radius: 3px;
  box-shadow: 0 20px 60px -2px rgba(27, 33, 58, 0.4);
}
.vm--container.scrollable .vm--modal {
  margin-bottom: 2px;
}
.vm--top-right-slot {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
}
.vm-transition--overlay-enter-active,
.vm-transition--overlay-leave-active {
  transition: all 50ms;
}
.vm-transition--overlay-enter,
.vm-transition--overlay-leave-active {
  opacity: 0;
}
.vm-transition--modal-enter-active,
.vm-transition--modal-leave-active {
  transition: all 400ms;
}
.vm-transition--modal-enter,
.vm-transition--modal-leave-active {
  opacity: 0;
  transform: translateY(-20px);
}
.vm-transition--default-enter-active,
.vm-transition--default-leave-active {
  transition: all 2ms;
}
.vm-transition--default-enter,
.vm-transition--default-leave-active {
  opacity: 0;
}
</style>
